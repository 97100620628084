<template>
  <header class="flex flex-col gap-4">
    <component
      :is="title[0].component"
      v-if="title.length"
      :key="title[0]._uid"
      v-editable="title[0]"
      v-bind="title[0]"
    />
    <component
      :is="text[0].component"
      v-if="text.length"
      :key="text[0]._uid"
      v-editable="text[0]"
      v-bind="text[0]"
      class="relative max-w-3xl"
    />
  </header>
</template>

<script>
export default {
  name: 'ProductListHeader',
  props: {
    title: {
      type: Array,
      required: true,
    },
    text: {
      type: Array,
      required: true,
    },
    image: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}
</script>
